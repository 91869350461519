/** @jsx $jsx */
import { $jsx } from 'framework7';
import urlParams from './js/urlParams.js';
import surveyApi from './js/surveyApi.js';

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $f7 = _ref.$f7,
      $update = _ref.$update,
      $on = _ref.$on,
      $store = _ref.$store;
  $on('pageInit', function () {
    if (urlParams.has('pin')) {
      window.app.store.state.pin = urlParams.get('pin');
      $('#pin').val(urlParams.get('pin'));
      $('.login-screen .login-button').click();
    }
  });

  var updatePin = function updatePin(e) {
    window.app.store.state.pin = e.target.value;
    $update();
  };

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div id="app">

    <!-- Left panel with cover effect-->
    <div class="panel panel-left panel-cover theme-dark panel-init">
      <div class="view">
        <div class="page">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
              <div class="title">Left Panel</div>
            </div>
          </div>
          <div class="page-content">
            <div class="block">Left panel content goes here</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Right panel with reveal effect-->
    <div class="panel panel-right panel-reveal theme-dark">
      <div class="view">
        <div class="page">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
              <div class="title">Right Panel</div>
            </div>
          </div>
          <div class="page-content">
            <div class="block">Right panel content goes here</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Your main view, should have "view-main" class -->
    <div class="view view-main view-init safe-areas" data-url="/">
      <div class="toolbar toolbar-bottom">
        <div class="toolbar-inner justify-content-left">
          <a href="https://www.daa.de/datenschutz" target="_blank" class="link external">Datenschutz</a>
          <a href="https://www.daa.de/impressum" target="_blank" class="link external margin-left">Impressum</a>
        </div>
      </div>
    </div>

    <!-- Popup -->
    <div class="popup star-help-popup">
      <div class="view">
        <div class="page">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
              <div class="title">Sternebewertung Hilfe</div>
              <div class="right">
                <a href="#" class="link popup-close">Schließen</a>
              </div>
            </div>
          </div>
          <div class="page-content">
            <div class="block">
              <p>Nicht jede Sternebewertung trifft auf jede Person zu. Deshalb können Sie Fragen, welche nicht auf Sie
                zutreffen, mit dem Toggle deaktivieren.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pin Screen -->
    <div class="login-screen" id="pin-screen">
      <div class="view">
        <div class="page">
          <div class="toolbar toolbar-bottom">
            <div class="toolbar-inner justify-content-left">
              <a href="https://www.daa.de/datenschutz" target="_blank" class="link external">Datenschutz</a>
              <a href="https://www.daa.de/impressum" target="_blank" class="link external margin-left">Impressum</a>
            </div>
          </div>

          <div class="bg-image"></div>
          <div class="page-content login-screen-content">
            <div class="display-flex justify-content-center">
              <img src="./static/images/daa-logo-mandant-2.png" class="margin" />
            </div>
            <div class="login-screen-title">Kundenbefragung</div>
            <div class="list">
              <ul>
                <li class="item-content item-input">
                  <div class="item-inner">
                    <div class="item-title item-label">PIN</div>
                    <div class="item-input-wrap">
                      <input type="text" id="pin" name="pin" placeholder="Geben Sie Ihre PIN ein"
                        @input="${updatePin}" />

                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="list">
              <ul>
                <li>
                  <a href="#" class="item-link list-button login-button">Anmelden</a>

                </li>
              </ul>
              <div class="block-footer">Geben Sie die dem Lehrgang zugewiesene PIN ein.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
`
    }
    ;
}

framework7Component.id = '4ef43e4dfc';
export default framework7Component;