/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component($props) {
  console.log($props);
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="card">
    {{#if $props.id}}id="{{$props.id}}"{{/if}}
  </div>
`
    }
    ;
}

framework7Component.id = 'e5596953fb';
export default framework7Component;