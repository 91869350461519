import $ from 'dom7';
import Framework7 from 'framework7/bundle';

// Import F7 Styles
import 'framework7/framework7-bundle.css';
import 'framework7-icons';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

// Import Routes
import routes from './routes.js';
// Import Store
import store from './store.js';

// Import main app component
import App from '../app.f7.html';

import testComponent from '../pages/components/test-component.f7.html';
Framework7.registerComponent('test-component', testComponent);

var app = new Framework7({
  name: 'daa-onlinebefragung', // App name
  theme: 'auto', // Automatic theme detection
  el: '#app', // App root element
  component: App, // App main component

  // App store
  store: store,
  // App routes
  routes: routes,
  // Register service worker
  serviceWorker: {
    path: '/dev/service-worker.js',
    scope: '/dev/',
  },
});

window.app = app;