/** @jsx $jsx */
import { $jsx } from 'framework7';
import pinValidator from '../js/pinValidator.js';
import surveyHtmlGenerator from '../js/surveyHtmlGenerator.js';
import surveyHtmlEvaluator from '../js/surveyHtmlEvaluator.js';
import { getDevice } from 'framework7';

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $f7 = _ref.$f7,
      $on = _ref.$on,
      $f7router = _ref.$f7router;
  $on('pageInit', function (e, page) {
    //desktop shrink
    // const device = getDevice();
    // if (device.desktop) {
    //   $('#survey-form-col').addClass('xsmall-33');
    // }
    //login screen
    var loginScreen = $f7.loginScreen.create({
      el: '.login-screen'
    });
    loginScreen.open(false);
    var listenToKeyPress = true;
    document.onkeydown = keyDown;

    function keyDown(e) {
      var x = e || window.event;
      var key = x.keyCode || x.which;

      if (key == 13 || key == 3) {
        if (!listenToKeyPress) {
          return;
        }

        login();
      }
    }

    $('.login-screen .login-button').on('click', function () {
      login();
    });

    function login() {
      console.log('login');
      console.log(e);
      console.log(page);
      console.log("current route: ".concat($f7router.currentRoute.path));

      if ($f7router.currentRoute.path != '/') {
        console.log("abort login");
        return;
      } //validation


      if (pinValidator.validate(function (isValid, surveyData) {
        if (isValid) {
          window.app.store.state.surveyData = surveyData;
          console.log(window.app.store.state.surveyData);
          loginScreen.close();
          surveyHtmlGenerator.generateForm($, $f7, surveyData);
          surveyHtmlEvaluator.initialize($, $f7, surveyData);
          document.onkeydown = null;
        } else {
          $f7.dialog.create({
            title: 'Ungültige PIN',
            text: 'Bitte geben Sie eine gültige PIN ein!',
            buttons: [{
              text: 'OK'
            }]
          }).open();
        }
      })) ;
    }
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="home">
    <div class="bg-image"></div>
    <!-- Scrollable page content-->
    <div class="page-content page-content-overlay">
      <div class="row display-flex justify-content-center">
        <div id="survey-form-col" class="col-auto xsmall-100 xlarge-50">
          <div class="card">
            <!--localization picker-->
            <div id="picker-localization-root" class="list no-hairlines-md block block-strong">
              <ul>
                <li>
                  <div class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-input-wrap">
                        <input type="text" placeholder="Deutsch" readonly="readonly" id="picker-localization"
                          class="text-align-center button button-fill" />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div class="card-header">
              <div class="block" style="width: 100%;">
                <div class="row">
                  <div class="col-50">
                    <p id="company" class="first-text-line">DAA Deutsche Angestellten-Akademie</p>
                    <p id="branch" class="text-line"></p>
                  </div>
                  <div class="col-50 no-margin margin-top">
                    <p class="text-align-right no-margin"><span id="title"></span></p>
                    <p class="text-align-right no-margin"><span id="course"></span></p>
                  </div>
                </div>
                <div class="separator"></div>
                <div class="row">
                  <div class="col-50">
                    <h2 id="headline" class="">Ihre Meinung ist uns wichtig</h2>
                  </div>
                  <div class="col-50">
                    <p class="text-align-right"><img id="logo" width="128px"
                        src="static/images/daa-logo-mandant-2.png" /></p>
                  </div>
                  <div class="row">
                    <div id="welcomeText" class="col">
                      <!-- <p class="text-line">Liebe Teilnehmer*innen,</p>
                      <p class="text-line">in diesen turbulenten und immer wieder überraschenden Wochen sammeln wir alle
                        neue Erfahrungen – sowohl Sie als Teilnehmer*in an einer DAA-Maßnahme als auch wir als DAA.</p>
                      <p class="text-line">Daraus ergeben sich viele Möglichkeiten zur Entwicklung.</p>
                      <p class="text-line">Bitte sagen Sie uns Ihre Meinung.</p>
                      <p>Die Befragung erfolgt anonym.</p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="survey-form" class="card-content card-content-padding">

            </div>
            <div class="card-footer display-flex justify-content-center">
              <a id="button-submit" class="button button-fill">Bewertung abschicken</a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
`
    }
    ;
}

framework7Component.id = '0c334f2529';
export default framework7Component;