
import { createStore } from 'framework7';

const store = createStore({
  state: {
    pin: '',
    surveyData: '',
  },
  getters: {
    pin({ state }) {
      return state.pin;
    }
  },
  actions: {
    setPin({ state }, pin) {
      state.pin = pin;
    }
  },
})
export default store;
