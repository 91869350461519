const jsonUtils = {
    tryParse: function (str) {
        try {
            var json = JSON.parse(str);
            return (typeof json === 'object');
        } catch (e) {
            return false;
        }
    }
}
export default jsonUtils;