import logoDictionary from "./logoDictionary";

const surveyHtmlGenerator = {
  addRadioGroup: function (element, bewgrpDaten) {
    let optionsHtml = "";
    let index = 0;
    bewgrpDaten.options.forEach((o) => {
      optionsHtml += `
            <li>
            <label class="item-radio item-radio-icon-end item-content">
                <input type="radio" name="radio-${bewgrpDaten.bewgrpDatenId}" value="${o.value}" ${bewgrpDaten.defaultValue == index || bewgrpDaten.defaultValue == o.value ? "checked" : ""
        } /><i class="icon icon-radio"></i>
                <div class="item-inner">
                    <div name="radio-caption" class="item-title">${o.text}</div>
                </div>
            </label>
            </li>`;
      index++;
    });

    element.append(`
        <div class="survey-question" bewgrpDaten="${btoa(
      JSON.stringify(bewgrpDaten)
    )}">
            <div class="card">
                <div class="card-header">
                    <div name="caption" class="">${bewgrpDaten.caption != undefined ? bewgrpDaten.caption : bewgrpDaten.localization[0].caption}</div>
                </div>
                <div class="card-content card-content-padding">
                    <div class="block list">
                        <ul>
                        ${optionsHtml}
                        </ul>
                    </div>
                </div>
            </div>
        </div>`);
  },
  addCheckGroup: function (element, bewgrpDaten) {
    let optionsHtml = "";
    bewgrpDaten.options.forEach((o) => {
      if (o.customText) {
        optionsHtml += `
                <li>
                    <input name="caption" type="text" class="option-custom-text" placeholder="${o.text}" />
                </li>`;
      } else {
        optionsHtml += `
                <li>
                <label class="item-checkbox item-content">
                    <input type="checkbox" name="checkbox" value="${o.value}" />
                    <div class="item-inner">
                        <div name="check-caption" class="item-title">${o.text}</div>
                        <i class="icons icon-checkbox"></i>
                    </div>
                </label>
                </li>`;
      }
    });

    element.append(`
        <div class="survey-question" bewgrpDaten="${btoa(
      JSON.stringify(bewgrpDaten)
    )}">
            <div class="card">
                <div class="card-header">
                    <div name="caption" class="">${bewgrpDaten.caption != undefined ? bewgrpDaten.caption : bewgrpDaten.localization[0].caption}</div>
                </div>
                <div class="card-content card-content-padding">
                    <div class="block list">
                        <ul>
                        ${optionsHtml}
                        </ul>
                    </div>
                </div>
            </div>
        </div>`);
  },
  addStars: function (element, bewgrpDaten) {
    element.append(`
        <div class="survey-question" bewgrpDaten="${btoa(
      JSON.stringify(bewgrpDaten)
    )}">
            <div class="card">
                <div class="card-header">
                <!--<div class="row">
                        <div class="col-75">
                            <a name="rating-toggle" class="button button-fill text-color-white align-text-center">
                                ${bewgrpDaten.required
        ? '<span>Pflichtangabe</span><i class="text-color-white f7-icons">exclamationmark_octagon</i>'
        : `Nicht relevant`
      }
                            </a>
                        </div>
                        <div class="col-25 display-flex justify-content-flex-end">
                            <a name="rating-toggle-help" class="button button-round button-outline align-text-center border-color-white text-color-white" style="width: 64px;">?</a>
                        </div>
                    </div>-->

                    <span name="caption" class="margin-right">${bewgrpDaten.caption != undefined ? bewgrpDaten.caption : bewgrpDaten.localization[0].caption}</span>

                    ${bewgrpDaten.required
        ? ""
        : `<label class="toggle toggle-init color-blue">
                        <input name="rating-toggle" type="checkbox" checked />
                        <span class="toggle-icon"></span>
                    </label>`
      }

                </div>
                <div class="card-content card-content-padding">
                    <!--<div class="row display-flex justify-content-center">
                        <div class="col">
                        <p name="caption" class="text-align-center">${bewgrpDaten.caption != undefined ? bewgrpDaten.caption : bewgrpDaten.localization[0].caption}</p>
                        </div>
                    </div>-->
                    <div class="row">
                        <div class="col">
                            <div class="rating">
                                <div class="stars text-align-center">
                                    <a class="f7-icons" value="1">star</a>
                                    <a class="f7-icons" value="2">star</a>
                                    <a class="f7-icons" value="3">star</a>
                                    <a class="f7-icons" value="4">star</a>
                                    <a class="f7-icons" value="5">star</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`);
  },
  addTextArea: function (element, bewgrpDaten) {
    element.append(`
        <div class="survey-question" bewgrpDaten="${btoa(
      JSON.stringify(bewgrpDaten)
    )}">
            <div class="card">
                <div class="card-header">
                    <div name="caption" class="">${bewgrpDaten.caption != undefined ? bewgrpDaten.caption : bewgrpDaten.localization[0].caption}</div>
                </div>
                <div class="card-content card-content-padding">
                    <div class="block">
                    <div class="list no-hairlines-md">
                    <ul>
                        <li class="item-content item-input">
                        <div class="item-inner">
                            <div class="item-input-wrap">
                                <textarea rows="5" placeholder="" maxlength="255"></textarea>
                            </div>
                        </div>
                        </li>
                    </ul>
                    </div>
                </div>
            </div>
      </div>`);
  },
  generateForm: function ($, $f7, surveyData) {

    if (surveyData.localization == undefined) {
      $('#picker-localization-root').remove();
    }
    else {
      // var dialog = $f7.dialog.create({
      //   text: 'Hello World',
      //   on: {
      //     opened: function () {
      //       console.log('Dialog opened')
      //     }
      //   },
      //   buttons: ['asd'],
      // });

      // dialog.open();

      console.log(`is desktop platform: ${$f7.device.desktop}`);

      if ($('#picker-localization')) {
        let picker = $f7.picker.create({
          inputEl: '#picker-localization',
          toolbarCloseText: 'schließen',
          // openIn: 'sheet',
          openIn: 'popover',
          backdrop: true,
          renderToolbar: function () {
            return '<div class="toolbar">' +
              '<div class="toolbar-inner">' +
              '<div class="left">' +
              '<p class="block">Wähen Sie Ihre Sprache aus</p>' +
              '</div>' +
              '<div class="right">' +
              '<a href="#" class="link sheet-close popover-close">OK</a>' +
              '</div>' +
              '</div>' +
              '</div>';
          },
          // render: function () {
          // },
          cols: [
            {
              textAlign: 'center',
              values: surveyData.localization.map(x => x.name),
              onChange: function (picker, language) {
                console.log(`Sprache geändert: ${language}?`);

                $('[name=caption]').forEach(caption => {

                  let bewgrpDaten = JSON.parse(atob($(caption).closest('.survey-question').attr('bewgrpdaten')));
                  console.log(bewgrpDaten);
                  $(caption).text(bewgrpDaten.localization[picker.cols[0].activeIndex].caption);

                });
              }
            }
          ],
          on: {
            open: function () {
              if ($f7.device.desktop) {
                $('.picker-popover').css('width', '450px');
                $('.picker-popover').css('min-width', '260px');
              }
            }
          }
        });

        picker.open();
      }
    }

    //dynamic base data
    let logoSrc = logoDictionary.get(surveyData.lgData.mandantId);

    $("#logo").attr("src", logoSrc);
    $("#title").text(surveyData.name);
    $("#course").text(surveyData.lgData.kopfKurz);

    $("#branch").text(surveyData.lgData.standortName);
    if (surveyData.lgData.mandantId != 2) {
      $('#company').empty();
    }

    $("#headline").text(surveyData.headlineText);
    $("#welcomeText")
      .html(`<p class=\"text-line\">${surveyData.welcomeText.replace(
        "\n",
        '</p><p class="text-line">'
      )}<br><div class="col info-box">
          <i class="col f7-icons" style="width:100%;">info_circle</i>
          <p class="col text-align-center">Fragen mit einem <label class="toggle toggle-init color-blue">
          <input type="checkbox" checked disabled />
          <span class="toggle-icon"></span>
          </label> sind optional, können ausgeblendet werden und werden dann nicht bewertet.</p>
          <hr>
            <div class="row">
              <div class="col-15 xsmall-33"></div>
              <img class="col-70 xsmall-33 align-content-center" src="static/images/star_tutorial.png"/>
              <div class="col-15 xsmall-33"></div>
            </div>
          </div></p><br>`);

    if (surveyData.lgData.mandantId === 23) {
      $("#logo").attr("src", "static/images/daa-stiftung-logo-mandant-23.png");
    }

    const surveyFormElement = $("#survey-form");
    surveyFormElement.empty();

    surveyData.bewgrpDaten.forEach((bewgrpDaten) => {
      if (bewgrpDaten.bewkritTyp === "single-choice") {
        this.addRadioGroup(surveyFormElement, bewgrpDaten);
      } else if (bewgrpDaten.bewkritTyp === "multiple-choice") {
        this.addCheckGroup(surveyFormElement, bewgrpDaten);
      } else if (bewgrpDaten.bewkritTyp === "rating-5") {
        this.addStars(surveyFormElement, bewgrpDaten);
      } else if (bewgrpDaten.bewkritTyp === "text") {
        this.addTextArea(surveyFormElement, bewgrpDaten);
      }
    });

    //rating toggle button
    $("a[name=rating-toggle-help]").on("click", function (e) {
      $f7.dialog
        .create({
          title: "Hilfe",
          text: `
                <div class="row">
                    <div class="col">
                        <div class="title"><u>Pflichtangabe</u></div>
                        <p>
                            Pflichtangaben lassen sich nicht deaktivieren und müssen beantwortet werden.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="title"><u>Relevant</u></div>
                        <p>
                            Es gibt Bewertungen, welche für Sie eventuell keine Rolle spielen. Diese können Sie über Tippen auf "Nicht Relevant" deaktivieren. Sie können diese Fragen zu jeder Zeit wieder re-aktivieren.
                        </p>
                    </div>
                </div>`,
          buttons: [
            {
              text: "OK",
            },
          ],
        })
        .open();
    });

    $("input[name=rating-toggle]").on("change", function (e) {
      console.log("toggle change");

      let buttonItem = $(e.target);

      let json = atob(
        buttonItem.closest(".survey-question").attr("bewgrpDaten")
      );
      console.log(json);
      let bewgrpDaten = JSON.parse(json);

      if (bewgrpDaten.required) {
        $f7.dialog
          .create({
            title: "Pflichtangabe",
            text: "Diese Frage ist eine Pflichtangabe und kann nicht deaktiviert werden!",
            buttons: [
              {
                text: "OK",
              },
            ],
          })
          .open();
        return;
      }

      let surveyItem = $(e.target)
        .parent()
        .parent()
        .parent()
        .parent()
        .find(".card-content");
      let isDisabled = surveyItem.hasClass("disabled");

      if (isDisabled) {
        buttonItem.text("Nicht relevant");
        surveyItem.removeClass("disabled");
      } else {
        buttonItem.text("Relevant");
        surveyItem.addClass("disabled");

        console.log($(e.target).parent().find("[rated]"));

        $(e.target)
          .parent()
          .parent()
          .parent()
          .parent()
          .find("[rated]")
          .text("star");
        $(e.target)
          .parent()
          .parent()
          .parent()
          .parent()
          .find("[rated]")
          .removeAttr("rated");
      }
    });

    //star rating
    $(".stars > a").on("mouseenter", function (e) {
      $(e.target)
        .parent()
        .parent()
        .parent()
        .parent()
        .find("a")
        .forEach((starElement) => {
          if ($(starElement).attr("value") <= $(e.target).attr("value")) {
            $(starElement).text("star_fill");
          } else {
            $(starElement).text("star");
          }
        });
    });

    $(".stars > a").on("click", function (e) {
      $(e.target)
        .parent()
        .parent()
        .parent()
        .parent()
        .find("a")
        .forEach((starElement) => {
          if ($(starElement).attr("value") <= $(e.target).attr("value")) {
            $(starElement).attr("rated", "");
          } else {
            $(starElement).removeAttr("rated");
          }
        });
    });

    $(".stars").on("mouseleave", function (e) {
      $(e.target)
        .parent()
        .parent()
        .parent()
        .parent()
        .find("a")
        .forEach((starElement) => {
          $(starElement).text(
            $(starElement).attr("rated") != undefined ? "star_fill" : "star"
          );
        });
    });
  },
};
export default surveyHtmlGenerator;