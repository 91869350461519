import surveyApi from './surveyApi.js';
import { request } from 'framework7';

const mailHelper = {
    send: function (body) {
        console.log('send mail:\n' + body);

        request.postJSON(surveyApi.getBaseUrl() + 'send-mail.php',
            {
                authorization: 'QmFzZS1BdXRob3JpemF0aW9uOjMwOXJ1MzQ5MHJ0dTMyNDlnajR0azQ1OTBpaGc0OTA1bXR2YjY0OTA1IT8=',
                mail: {
                    body: body
                }
            },
            function (response) {
                console.log(response);
            });
    }
}
export default mailHelper;