import { request } from 'framework7';
import jsonUtils from './jsonUtils.js';

const surveyApi = {
    getBaseUrl: function () {
        console.log("surveyApi.getBaseUrl()");
        return 'https://onlinebefragung.daa.de/';
    },
    getJson: function (response) {
        console.log("surveyApi.getJson(response)");
        request.get('https://onlinebefragung.daa.de/get-lg-data.php').then((res) => {
            let base64Decoded = window.atob(res.data);
            let json = JSON.parse(base64Decoded);
            response(json);
        });
    },
    getSurveyData: function (pin, response) {
        console.log("surveyApi.getSurveyData(pin, response)");
        request.get(`https://onlinebefragung.daa.de/get-survey.php?pin=${pin}`).then((res) => {
            if (jsonUtils.tryParse(res.data)) {
                // let base64Decoded = window.atob(res.data);
                // let json = JSON.parse(base64Decoded);
                let json = JSON.parse(res.data);
                response(json);
            }
            else {
                response(null);
            }
        });
    }
}
export default surveyApi;