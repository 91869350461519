const urlParams = {
    has: function (name) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.has(name) && urlParams.get(name) != undefined && urlParams.get(name) != '';
    },
    get: function (name) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get(name);
    },
    reset: function () {
        window.location.search = '';
    }
}
export default urlParams;