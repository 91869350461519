import mailHelper from './mailHelper.js';

const surveyHtmlEvaluator = {
    initialize: function ($, $f7, surveyData) {
        $('#button-submit').on('click', function (e) {
            let bewgrpLfd = 1;
            let resultText = '';
            let isSurveyValid = true;

            $('.survey-question').forEach(htmlElement => {
                let domElement = $(htmlElement);
                console.log(domElement);
                let bewgrpDaten = JSON.parse(atob(domElement.attr('bewgrpDaten')));
                console.log(bewgrpDaten);

                let rowText = `${surveyData.lgData.kopfId}|${bewgrpDaten.bewgrpDatenId}|${bewgrpLfd++}|[0]|[1]|[2]|[3]|[4]|[5]|[6]|[7]|[8]|[9]|[bemerkung]|ROW-DELIMITER-b7cd86ab-89b3-4364-a8d1-f1fe85fafe21\r\n`;

                if (bewgrpDaten.bewkritTyp === 'single-choice') {
                    let element = $(domElement.find('input[type=radio]:checked')[0]);
                    let rating = element.val();
                    console.log('rating: ' + rating);

                    for (let i = 0; i < 10; i++) {
                        rowText = rowText.replace(`[${i}]`, rating == i + 1 ? 1 : 0);
                    }

                    rowText = rowText.replace('[bemerkung]', element.parent().find('.item-title').text());

                    console.log('rowText: ' + rowText);
                }
                else if (bewgrpDaten.bewkritTyp === 'multiple-choice') {
                    let checkboxArray = domElement.find('input[type=checkbox]:checked');
                    console.log(checkboxArray);

                    for (let i = 0; i < 10; i++) {
                        rowText = rowText.replace(`[${i}]`, 0);
                    }

                    let bemerkungText = '';
                    let delimiter = ', ';
                    checkboxArray.forEach(checkboxHtmlElement => {
                        bemerkungText += $(checkboxHtmlElement).val() + delimiter;
                    });

                    let customTextElements = domElement.find('input[type=text]');
                    customTextElements.forEach(textHtmlElement => {
                        let value = $(textHtmlElement).val();
                        if (value.length > 0) {
                            bemerkungText += value + delimiter;
                        }
                    });
                    bemerkungText = bemerkungText.slice(0, -delimiter.length);

                    rowText = rowText.replace('[bemerkung]', bemerkungText);
                    console.log('rowText: ' + rowText);
                }
                else if (bewgrpDaten.bewkritTyp === 'rating-5') {

                    let rating = domElement.find('[rated]').length;
                    let isDisabled = domElement.find('.disabled').length > 0;
                    console.log('rating: ' + rating);

                    if ((bewgrpDaten.required || !isDisabled) && rating === 0) {
                        if (isSurveyValid) {
                            isSurveyValid = false;

                            $f7.dialog.create({
                                title: 'Pflichtangabe',
                                text: `Bitte bewerten Sie alle Pflichtangaben und alle optionalen, aktiven Fragen, andernfalls ist eine Bewertung nicht möglich.<br>Wenn Sie optionale Fragen nicht beantworten möchten, setzen Sie diese bitte auf "Nicht Relevant".<br><br>Vielen Dank!`,
                                buttons: [
                                    {
                                        text: 'OK',
                                    },
                                ],
                            }).open();
                        }
                    }

                    for (let i = 0; i < 10; i++) {
                        rowText = rowText.replace(`[${i}]`, rating == i + 1 ? 1 : 0);
                    }

                    rowText = rowText.replace('[bemerkung]', '');

                    console.log('rowText: ' + rowText);
                }
                else if (bewgrpDaten.bewkritTyp === 'text') {
                    let text = domElement.find('textarea').val();
                    console.log('text: ' + text);

                    for (let i = 0; i < 10; i++) {
                        rowText = rowText.replace(`[${i}]`, 0);
                    }

                    rowText = rowText.replace('[bemerkung]', text);

                    console.log('rowText: ' + rowText);
                }

                resultText += rowText;
            });

            console.log(resultText);
            console.log("isSurveyValid: " + isSurveyValid);

            if (isSurveyValid) {
                mailHelper.send(resultText);
                window.app.views.main.router.navigate('/thanks/');
            }
        });
    }
}
export default surveyHtmlEvaluator;