import HomePage from '../pages/home.f7.html';
import Thanks from '../pages/thanks.f7.html';
import NotFoundPage from '../pages/404.f7.html';

var routes = [
  {
    name: 'homePage',
    path: '/',
    component: HomePage,
  },
  {
    path: '/thanks/',
    component: Thanks,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;