/** @jsx $jsx */
import { $jsx } from 'framework7';
import urlParams from '../js/urlParams.js';

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $on = _ref.$on;
  $on('pageInit', function () {
    $('#button-start').on('click', function (e) {
      urlParams.reset();
      window.app.views.main.router.navigate({
        name: 'homePage',
        params: {
          pin: ''
        }
      });
    });
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page" data-name="thanks">
        <div class="page-content display-flex justify-content-center">
            <div class="row display-flex justify-content-center" style="height: 20vh; margin: auto">
                <div class="col-100 block-title text-align-center"><u>DAA Kundenbefragung</u></div>
                <div class="col-100 text-align-center"><i class="f7-icons">hand_thumbsup</i></div>
                <div class="col-100 text-align-center">Vielen Dank, dass Sie an dieser Umfrage teilgenommen haben!</div>
                <div class="col-25 text-align-center margin">
                    <a id="button-start" class="item-link list-button login-button">Zurück zur Startseite</a>
                </div>
            </div>
        </div>
    </div>
`
    }
    ;
}

framework7Component.id = 'a1c67014c9';
export default framework7Component;