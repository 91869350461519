import surveyApi from './surveyApi.js';

const pinValidator = {
    validate: function (callback) {
        console.log(`pinValidator.isValid(): ${window.app.store.state.pin}`);
        if (window.app.store.state.pin != undefined && window.app.store.state.pin != '') {
            surveyApi.getSurveyData(window.app.store.state.pin, (callbackData) => {
                callback(callbackData != null, callbackData);
            });
        }
        else {
            callback(false, null);
        }
    }
};
export default pinValidator;